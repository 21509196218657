import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";

export default function Blog() {
  return (
    <>
      <Menu />
      <Footer />
    </>
  );
}
